import styled from 'styled-components'
import media from 'styled-media-query'

export const Alert = styled.div`
  transform: translate(0, 0);
  ${media.greaterThan('small')`
    margin: 40px auto;
    width: 75%;
  `}
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #0000;
  border-radius: 0.25rem;
`
