import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  colors: {
    primaryDark: '#110f10',
    primaryLight: '#202020',
    secondary: `#f7541b`,
    secondaryDark: '#000000',
    input: '#fff',
    body: 'rgba(255, 255, 255, 0.75)',
    success: '#00bc8c',
    danger: '#E74C3C',
    impression: '#fff',
  },
}
