import React, { useEffect } from 'react'
import Recaptcha from 'react-recaptcha'
import useSSR from 'use-ssr'

interface SiteRecaptchaProps {
  onVerified: (token: string) => void
}

export const SiteRecaptcha = (props: SiteRecaptchaProps): JSX.Element => {
  let recaptchaInstance: Recaptcha | null

  const { isBrowser } = useSSR()
  const verifyCallback = (token: string) => {
    props.onVerified(token)
  }

  useEffect(() => {
    if (recaptchaInstance) {
      recaptchaInstance.reset()
    }
  }, [])

  if (!isBrowser || !process.env.GATSBY_SITE_RECAPTCHA_KEY) return <></>
  return (
    <Recaptcha
      ref={e => (recaptchaInstance = e)}
      elementID="recaptcha-contact"
      sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
      render="explicit"
      verifyCallback={verifyCallback}
    />
  )
}
