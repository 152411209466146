import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

export const SiteFooter = (): JSX.Element => {
  return (
    <Footer>
      <FooterContainer>
        <CopyrightNotice>
          &copy; Copyright {new Date().getUTCFullYear()} Raven Code Limited. All
          Rights Reserved.
        </CopyrightNotice>
        <RegisteredCompanyInformation>
          Raven Code Limited is a registered company in England and Wales.
          Company No. 12511035. VAT No. 374992248.
        </RegisteredCompanyInformation>
        <LegalLinks>
          <LegalLinkItem>
            <LegalLink
              to="/privacy-policy"
              title="Read Raven Code's Privacy Policy"
            >
              Privacy Policy
            </LegalLink>
          </LegalLinkItem>
          <LegalLinkItem>
            <LegalLink
              to="/terms-of-service"
              title="Read Raven Code's Terms of Service"
            >
              Terms of Service
            </LegalLink>
          </LegalLinkItem>
        </LegalLinks>
      </FooterContainer>
    </Footer>
  )
}

const Footer = styled.footer`
  display: flex;
  text-align: center;
  padding: 2em;
  background-color: ${({ theme }) => theme.colors.primaryDark};
`

const FooterContainer = styled.div`
  flex: 1;
`

const CopyrightNotice = styled.p``

const RegisteredCompanyInformation = styled.p``

const LegalLinks = styled.ul`
  list-style: none;
  list-style-type: none;
  margin: auto;
  padding: 0;
`

const LegalLinkItem = styled.li`
  display: inline;
  &:not(:last-child)::after {
    content: '\\00b7';
    padding: 1em;
  }
`

const LegalLink = styled(Link)`
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: underline;
  }
`
