/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect } from 'react'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import styled, { ThemeProvider } from 'styled-components'
import media from 'styled-media-query'
import { GlobalStyles } from '../GlobalStyles'
import { theme } from '../theme'
import { ContactUs } from './ContactUs'
import { NavigationBar } from './NavigationBar'
import { SiteFooter } from './SiteFooter'

export const Layout = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    console.log(
      `      ██████╗  █████╗ ██╗   ██╗███████╗███╗   ██╗     ██████╗ ██████╗ ██████╗ ███████╗
      ██╔══██╗██╔══██╗██║   ██║██╔════╝████╗  ██║    ██╔════╝██╔═══██╗██╔══██╗██╔════╝
      ██████╔╝███████║██║   ██║█████╗  ██╔██╗ ██║    ██║     ██║   ██║██║  ██║█████╗
      ██╔══██╗██╔══██║╚██╗ ██╔╝██╔══╝  ██║╚██╗██║    ██║     ██║   ██║██║  ██║██╔══╝
      ██║  ██║██║  ██║ ╚████╔╝ ███████╗██║ ╚████║    ╚██████╗╚██████╔╝██████╔╝███████╗
      ╚═╝  ╚═╝╚═╝  ╚═╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝     ╚═════╝ ╚═════╝ ╚═════╝ ╚══════╝`
    )
    console.log(
      'Welcome to Raven Code, get in touch if you think we can help you!'
    )
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <NavigationBar />
        <Main id="maincontent">
          {children}
          <ContactUs />
        </Main>
        <SiteFooter />
      </ThemeProvider>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        buttonClasses="accept-consent-btn"
        onAccept={() => {
          Cookies.set('gdpr-google-analytics', 'true')
          Cookies.set('gdpr-facebook-pixel', 'true')
        }}
      >
        This website uses cookies to ensure you get the best experience on our
        website.{' '}
        <a href="/privacy-policy" title="Learn more about our cookie usage">
          Read our Privacy Policy to learn more
        </a>
        .
      </CookieConsent>
    </>
  )
}

const Main = styled.main`
  margin-top: 136px;
  ${media.lessThan('small')`
    margin-top: 100px;
  `}
`
