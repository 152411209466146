/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby'
import { FixedObject } from 'gatsby-image'
import React from 'react'
import Helmet from 'react-helmet'

type MetaProps = JSX.IntrinsicElements['meta']

interface SEOProps {
  description?: string
  lang?: string
  meta?: Array<MetaProps>
  title?: string
}

interface SEOStaticQuery {
  site: {
    siteMetadata: {
      title: string
      description: string
      author: string
      siteUrl: string
      twitter: string
      github: string
      linkedIn: string
    }
  }
  logoImage: {
    childImageSharp: {
      fixed: FixedObject
    }
  }
  socialImage: {
    childImageSharp: {
      fixed: FixedObject
    }
  }
}

const SEO = ({ description, lang, meta, title }: SEOProps): JSX.Element => {
  const { site, socialImage, logoImage } = useStaticQuery<SEOStaticQuery>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            twitter
            github
            linkedIn
          }
        }
        logoImage: file(relativePath: { eq: "raven-code-symbol-black.png" }) {
          childImageSharp {
            fixed(width: 112, height: 112) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        socialImage: file(
          relativePath: { eq: "raven-code-open-graph-social.png" }
        ) {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const metaDescription = site.siteMetadata.description
  const siteDescription = description || site.siteMetadata.description

  const metaTitle = site.siteMetadata.title
  const siteTitle = title

  const metaImage = `${site.siteMetadata.siteUrl}${socialImage.childImageSharp.fixed.src}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={metaTitle}
      title={siteTitle}
      titleTemplate={`%s | ${metaTitle}`}
      meta={([
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:image:alt`,
          content: `Raven bird with the text Raven Code to right hand side`,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `630`,
        },
        {
          name: `description`,
          content: siteDescription,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ] as Array<MetaProps>).concat(meta || [])}
    >
      <script type="application/ld+json">
        {`
          [
            {
              "@context": "https://schema.org",
              "@type": "Corporation",
              "name": "${site.siteMetadata.title}",
              "alternateName": "Raven Code",
              "url": "${site.siteMetadata.siteUrl}",
              "logo": "${site.siteMetadata.siteUrl}${logoImage.childImageSharp.fixed.src}",
              "sameAs": [
                "https://twitter.com/${site.siteMetadata.twitter}",
                "https://github.com/${site.siteMetadata.github}",
                "https://www.linkedin.com/company/${site.siteMetadata.linkedIn}"
              ]
            },
            {
              "@context": "https://schema.org/",
              "@type": "WebSite",
              "name": "${site.siteMetadata.title}",
              "url": "${site.siteMetadata.siteUrl}"
            }
          ]
        `}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
