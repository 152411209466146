import React, { FormEvent, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { ErrorAlert } from './ErrorAlert'
import { SiteRecaptcha } from './SiteRecaptcha'
import { SuccessAlert } from './SuccessAlert'

interface ContactUsData {
  name: string
  email: string
  telephone: string
  message: string
  'g-recaptcha-response': string
  [key: string]: string
}

const encode = (data: ContactUsData): string => {
  return Object.keys(data)
    .filter(Boolean)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const ContactUsForm = (): JSX.Element => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [telephone, setTelephone] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [recaptchaResponse, setRecaptchaResponse] = useState<string>('')
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const reset = useCallback((): void => {
    setName('')
    setEmail('')
    setTelephone('')
    setMessage('')
  }, [])

  const handleSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    if (recaptchaResponse) {
      setError(false)
      setSuccess(false)
      try {
        await fetch('/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: encode({
            'form-name': 'contact',
            name,
            email,
            telephone,
            message,
            'g-recaptcha-response': recaptchaResponse,
          }),
        })
        setSuccess(true)
        reset()
      } catch (error) {
        console.error(error.message)
        setError(true)
      }
    } else {
      setError(true)
    }
  }

  const resetStatus = useCallback((): void => {
    setError(false)
    setSuccess(false)
  }, [])

  return (
    <>
      {error && (
        <ErrorAlert title="Whoops">
          <>
            Looks like we&apos;re having issues with your details, please make
            sure the details are correct and try sending us a message again.
          </>
        </ErrorAlert>
      )}
      {!error && success && (
        <SuccessAlert title="Awesome">
          <>
            <span role="img" arial-label="Raising hands">
              🙌
            </span>{' '}
            Thanks, we&apos;re super excited to hear about your project and will
            be in touch soon.
          </>
        </SuccessAlert>
      )}
      <Form
        name="contact"
        method="post"
        data-netlify
        data-netlify-recaptcha
        onSubmit={handleSubmit}
      >
        <FormNameHiddenPanel>
          <input type="hidden" name="form-name" value="contact" />
        </FormNameHiddenPanel>
        <FormGroup>
          <Label htmlFor="name">Full Name</Label>
          <Input
            id="name"
            name="name"
            placeholder="Enter your full name"
            required
            value={name}
            onChange={evt => {
              resetStatus()
              setName(evt.target.value)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            name="email"
            placeholder="Enter your email"
            type="email"
            required
            value={email}
            onChange={evt => {
              resetStatus()
              setEmail(evt.target.value)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="telephone">Telephone (optional)</Label>
          <Input
            id="telephone"
            name="telephone"
            type="tel"
            placeholder="Enter your telephone number"
            value={telephone}
            onChange={evt => {
              resetStatus()
              setTelephone(evt.target.value)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="message">Tell us about your project</Label>
          <TextArea
            id="message"
            name="message"
            placeholder="Enter your project details"
            cols={30}
            rows={4}
            value={message}
            onChange={evt => {
              resetStatus()
              setMessage(evt.target.value)
            }}
            required
          ></TextArea>
        </FormGroup>
        <FormGroup>
          <SiteRecaptcha
            onVerified={token => {
              resetStatus()
              setRecaptchaResponse(token)
            }}
          />
        </FormGroup>
        <SendMessageButton type="submit">Send Message</SendMessageButton>
      </Form>
    </>
  )
}

const Form = styled.form`
  margin: 0;
  width: 100%;
  ${media.greaterThan('small')`
    margin: auto;
    width: 75%;
  `}
`

const FormNameHiddenPanel = styled.div`
  height: 0;
  width: 0;
`

const input = css`
  background-color: ${({ theme }) => theme.colors.primaryLight};
  color: ${({ theme }) => theme.colors.input};
  border: 0;
  display: block;
  line-height: 1.9;
  outline: none;
  transition: width 0.3s cubic-bezier(0.72, 0.16, 0.345, 0.875);
  margin-right: -62px;
  box-shadow: none;
  border: 0;
  line-height: 1.9;
  outline: none;
  padding: 1rem 1.5rem;
  width: 100%;
`

const Input = styled.input`
  ${input}
`

const TextArea = styled.textarea`
  ${input}
`

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`

const SendMessageButton = styled.button`
  border: 0;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 0.87rem;
  letter-spacing: 0.1rem;
  line-height: inherit;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.8rem 2.5rem;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.input};
  color: ${({ theme }) => theme.colors.primaryDark};
  width: 100%;
  ${media.greaterThan('medium')`
    width: auto;
  `}
`

const FormGroup = styled.div`
  margin-bottom: 2.8rem;
  position: relative;
`
