import styled, { css, StyledProps } from 'styled-components'

export interface StyledContainerProps {
  lightTheme?: boolean
}

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryLight};
  ${(props: StyledProps<StyledContainerProps>) =>
    props.lightTheme &&
    css`
      background-color: #fff;
      color: ${({ theme }) => theme.colors.primaryLight};
      * {
        color: ${({ theme }) => theme.colors.primaryLight};
      }
    `}
`

export default StyledContainer
