import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled, { StyledProps } from 'styled-components'
import media from 'styled-media-query'
import LogoSvg from '../assets/images/raven-code-logo-white.svg'
import { SkipToContent } from './SkipToContent'

interface LogoImageProps {
  smallNavigation: boolean
}

export const NavigationBar = (): JSX.Element => {
  const [smallNavigation, setSmallNavigation] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = (): void => {
    setScrollPosition(document.body.getBoundingClientRect().top)
    setSmallNavigation(
      document.body.getBoundingClientRect().top < scrollPosition ||
        scrollPosition > 0
    )
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Header smallNavigation={smallNavigation}>
      <SkipToContent contentId="maincontent" />
      <Logo>
        <LogoLink to="/" title="Raven Code Home">
          <LogoImage smallNavigation={smallNavigation} />
        </LogoLink>
      </Logo>
      <NavBar>
        <NavLink href="/#services">Services</NavLink>
        <NavLink href="/#team">Team</NavLink>
        <NavLink href="/case-studies">Case Studies</NavLink>
        <NavLink href="#contact">Contact Us</NavLink>
      </NavBar>
    </Header>
  )
}

const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryDark};
  line-height: 1;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  ${media.greaterThan('small')`
    -webkit-box-orient: vertical;
    display: flex;
  `}
  ${(props: StyledProps<LogoImageProps>) =>
    props.smallNavigation &&
    `
      background-color: ${props.theme.colors.primaryDark};
    `}
`

const NavBar = styled.nav`
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  ${media.greaterThan('small')`
    float: right;
  `}
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: auto;
  justify-content: center;
  ${media.greaterThan('small')`
    float: left;
  `}
`

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  background-color: #0000;
  color: #fff;
  text-decoration: underline;
  -webkit-text-decoration-skip: objects;
`

const LogoImage = styled(LogoSvg)`
  display: inline-block;
  ${media.lessThan('small')`
    height: 60px;
    max-height: 60px;
    g#text {
      display: none;
    }
  `}
  height: 120px;
  max-height: 120px;
  ${(props: StyledProps<LogoImageProps>) =>
    props.smallNavigation &&
    `
    g#text {
      display: none;
    }
    height: 60px;
    max-height: 60px;
  `}
`

const NavLink = styled.a`
  display: block;
  padding: 0.25rem 0;
  font-weight: 700;
  background-color: transparent;
  text-decoration: none;
  margin-left: 1rem;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: underline;
  }
`
