import React from 'react'
import styled from 'styled-components'

interface SkipToContentProps {
  contentId: string
}

export const SkipToContent = ({
  contentId,
}: SkipToContentProps): JSX.Element => (
  <div>
    <SkipToContentLink
      href={`#${contentId}`}
      title="Skip navigation and straight to the main content"
    >
      skip to main content
    </SkipToContentLink>
  </div>
)

const SkipToContentLink = styled.a`
  padding: 6px;
  position: absolute;
  top: -40px;
  left: 0px;
  color: #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-bottom-right-radius: 8px;
  background: ${({ theme }) => theme.colors.secondary};
  -webkit-transition: top 1s ease-out;
  transition: top 1s ease-out;
  z-index: 100;
  &:focus {
    position: absolute;
    left: 0px;
    top: 0px;
    outline-color: #0000;
    -webkit-transition: top 0.1s ease-in;
    transition: top 0.1s ease-in;
  }
`
