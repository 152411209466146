import styled from 'styled-components'

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.input};
  border: none;
  position: relative;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: 0;
`
