import React from 'react'
import styled from 'styled-components'
import { Alert, AlertProps, Body, Content, Heading } from './Alert'

export const ErrorAlert = ({ title, children }: AlertProps): JSX.Element => (
  <ErrorAlertPanel>
    <Content>
      <Heading>{title}</Heading>
      <Body>{children}</Body>
    </Content>
  </ErrorAlertPanel>
)

const ErrorAlertPanel = styled(Alert)`
  background-color: ${({ theme }) => theme.colors.danger};
`
